import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from 'gatsby'
import media from "../../../styles/breakPoints"
import { useIntl } from 'gatsby-plugin-intl';

import Layout from "../../../components/Layout";
import SEO from "../../../components/seo"

import Section from "../../../objects/Section"
import ProductSection from "../../../components/ProductSection"

const WrapSections = styled(Section)`
   background-color: ${props => props.theme.color.grayLight};
   flex-direction: column;
   padding: 0;
   align-items: flex-start;

   ${media.greaterThan("md")`
      padding: 20px 0;
   `}

   ${media.greaterThan("lg")`
      padding: 40px 0;
   `}

`

const ContatoresReles = () => {

   const intl = useIntl();

   const content = useStaticQuery(
      graphql`
         query {
            imgCR_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row1" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCR_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row2" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCR_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row3" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCR_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row4" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCR_Row5: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row5" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            imgCR_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles/row6" }},
                     sort: {order: ASC, fields: childImageSharp___fixed___originalName}) {
               edges {
                  node {
                     childImageSharp {
                        fluid(maxHeight: 180) {
                           ...GatsbyImageSharpFluid
                        }
                     }
                  }
               }
            }
            pdfCR_Row1: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles-row1" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCR_Row2: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles-row2" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCR_Row3: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles-row3" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCR_Row4: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles-row4" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
            pdfCR_Row6: allFile (
               filter: 
                  {  relativeDirectory: { eq: "contatores-reles-row6" }},
                     sort: {order: ASC, fields: name} ) {
               edges {
                  node {
                     publicURL
                  }
               }
               totalCount
            }
         }      
      `
    )
  
   return (
      <Layout>
         <SEO 
            lang={intl.locale}
            title={intl.formatMessage({ id: "navdropdown.item2" })}
            keywords={[ `indústria metalúrgica em São Paulo`, `contator corrente contínua`, `contator composição variável sobre barra`, `contator para caminhões`, `contatos para locomotivas`, `relé de sobrecorrente`, `relés diversos`, `contator para empilhadeira`, `contatos para carro elétrico` ]}
         />
         <WrapSections>
            <ProductSection
               tagAs="h1"
               productline="contatoresrelespage"
               row="contentCR_Row1"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row1.section" })}
               element="glide-contatores-reles-row1" 
               children={content.imgCR_Row1.edges}
               catalog={content.pdfCR_Row1.edges}
               totalCount={content.pdfCR_Row1.totalCount}
            />
            <ProductSection
               productline="contatoresrelespage"
               row="contentCR_Row2"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row2.section" })}
               element="glide-contatores-reles-row2" 
               children={content.imgCR_Row2.edges}
               catalog={content.pdfCR_Row2.edges}
               totalCount={content.pdfCR_Row2.totalCount}
            />
            <ProductSection
               productline="contatoresrelespage"
               row="contentCR_Row3"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row3.section" })}
               element="glide-contatores-reles-row3" 
               children={content.imgCR_Row3.edges}
               catalog={content.pdfCR_Row3.edges}
               totalCount={content.pdfCR_Row3.totalCount}
            />
            <ProductSection
               productline="contatoresrelespage"
               row="contentCR_Row4"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row4.section" })}
               element="glide-contatores-reles-row4" 
               children={content.imgCR_Row4.edges}
               catalog={content.pdfCR_Row4.edges}
               totalCount={content.pdfCR_Row4.totalCount}
            />
            <ProductSection
               productline="contatoresrelespage"
               row="contentCR_Row5"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row5.section" })}
               element="glide-contatores-reles-row5" 
               children={content.imgCR_Row5.edges}
               // catalog={content.pdfCR_Row5.edges}
               totalCount={0}
            />
            <ProductSection
               productline="contatoresrelespage"
               row="contentCR_Row6"
               section={intl.formatMessage({ id: "contatoresrelespage.contentCR_Row6.section" })}
               element="glide-contatores-reles-row6" 
               children={content.imgCR_Row6.edges}
               catalog={content.pdfCR_Row6.edges}
               totalCount={content.pdfCR_Row6.totalCount}
            />
         </WrapSections>
      </Layout>
   )
}

export default ContatoresReles